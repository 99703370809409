<template>
  <div
    class="w-full border mb-1 rounded border-grey-light-3 p-2 flex flex-row hover:border-blue-dark-1 cursor-pointer"
    :class="isActive ? 'border-blue-light-5 bg-blue-light-1' : null"
  >
    <div
      v-if="isActive"
      class="w-14 h-14 min-w-14 min-h-14 mt-0.5 rounded-full flex justify-center items-center"
      :class="disabled ? 'bg-grey-light' : 'bg-blue-dark-1'"
    >
      <img src="../../assets/img/icons/check.svg" />
    </div>
    <div
      v-else
      class="w-14 h-14 mt-0.5 bg-grey-light-1 rounded-full min-w-14 min-h-14"
    />
    <div class="ml-2">
      <h3 class="text-md text-grey-dark-1 font-semibold title">
        {{ title }}
      </h3>
      <p class="text-sm-2 font-normal text-grey-dark-2">{{ description }}</p>
    </div>
  </div>
</template>
<script>
export default {
  name: "Radio",
  props: {
    isActive: { default: null, type: Boolean },
    title: { type: String, default: "" },
    description: { type: String, default: "" },
    disabled: { type: Boolean, default: false },
  },
};
</script>
<style lang="scss">
.title {
  margin-bottom: 6px;
}
</style>
