var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mr-2"},[_c('div',{staticClass:"flex"},[_vm._m(0),_c('div',{staticClass:"flex flex-grow gap-2 justify-end"},[_c('Button',{attrs:{"text":"Cancel","type":"secondary","size":"medium"},nativeOn:{"click":function($event){return _vm.cancel.apply(null, arguments)}}}),_c('Button',{attrs:{"text":"Save","type":"primary","iconRight":_vm.arrowRight,"size":"medium","disabled":_vm.$v.$invalid || _vm.isDisabled || _vm.isAssessmentDurationValid},nativeOn:{"click":function($event){return _vm.save.apply(null, arguments)}}})],1)]),_c('div',{staticClass:"bg-white shadow rounded px-2 py-3 mt-3 mb-5.5"},[_c('div',{staticClass:"max-w-1/2 min-w-600"},[_c('p',{staticClass:"font-headers font-bold text-mdl text-grey-light mb-2"},[_vm._v(" Details and configuration ")]),_c('p',{staticClass:"text-mdh text-grey-dark-1"},[_vm._v(" Select an intuitive and distinctive name for this component and concise description so that customers can easily understand what the assessment aims to accomplish for them. ")]),_c('div',{staticClass:"ml-1 my-3"},[_c('p',{staticClass:"text-sm-2 font-medium text-grey-light mb-1"},[_vm._v("Product Type")]),_c('div',{staticClass:"relative"},[(_vm.isEditMode)?_c('div',{staticClass:"absolute inset-0 bg-white z-10 opacity-50"}):_vm._e(),_vm._l((_vm.productType),function(option){return _c('Radio',{key:option.name,attrs:{"title":option.name,"description":option.description,"disabled":_vm.isEditMode,"isActive":_vm.selectedProductType === option.type},nativeOn:{"click":function($event){return _vm.onSelect(option.type)}}})})],2)]),_c('InputField',{staticClass:"ml-1 mb-3",attrs:{"label":"Product Name","placeholder":"e.g. Soft Skills Self Assessment","onChange":function (value) { return (_vm.productName = value); },"defaultValue":_vm.productName,"maxlength":50,"isTextOk":!_vm.productDetails.productName.$dirty
            ? null
            : _vm.productDetails.productName.required
              ? null
              : false,"errorText":"Product name is required"}}),_c('TextArea',{staticClass:"ml-1 mb-4",attrs:{"label":"Description","placeholder":"Brief explanation of what the component does","onChange":function (value) { return (_vm.productDescription = value); },"defaultValue":_vm.productDescription,"isTextOk":!_vm.productDetails.description.$dirty
            ? null
            : _vm.productDetails.description.required
              ? null
              : false,"errorText":"Product description is required"}}),_c('div',{staticClass:"ml-1"},[_c('CustomCheckbox',{staticClass:"w-3/4",attrs:{"title":"Allow randomization of questions","description":"Allow company admins to randomize the questions for the entire assessment","onClick":_vm.changeRandomization,"active":_vm.randomization}}),_c('div',{staticClass:"flex gap-2"},[_c('CustomCheckbox',{staticClass:"w-3/4",attrs:{"title":"Allow completion in multiple sessions","description":"Allow company admins to enable participants to complete the assessment in multiple sessions","onClick":_vm.changeMultipleSessions,"active":_vm.multipleSessions}}),(!_vm.multipleSessions)?_c('InputField',{staticClass:"w-125",attrs:{"label":"Time allocation (min)","onChange":function (value) { return (_vm.timeAllocation = value); },"defaultValue":_vm.timeAllocation,"disabled":_vm.multipleSessions,"type":"number","isTextOk":_vm.multipleSessions ? null : +_vm.timeAllocation > 0 ? null : false,"errorText":"Time allocation must be at least 30 min"}}):_vm._e()],1)],1),_c('CustomCheckbox',{staticClass:"w-3/4",attrs:{"title":"Allow going back and changing responses","description":"Allow company admins to enable participants to change responses for previously answered questions.","onClick":_vm.changeGoBack,"active":_vm.goBack}}),_c('NewSelect',{attrs:{"label":"When to Publish Results for Employees","options":_vm.publishResultsOptions,"value":_vm.publishResults,"onSelect":_vm.setPublishResults,"preselectFirst":false,"data-testid":"results-dropdown"}}),_c('InputField',{staticClass:"w-125 mt-3",attrs:{"label":"Assessment duration","onChange":function (value) { return (_vm.assessmentDuration = value); },"defaultValue":_vm.assessmentDuration,"isTextOk":_vm.isAssessmentDurationOk,"errorText":"Invalid input format. Please enter a single number or a number range (number - number)"}})],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h1',{staticClass:"text-xl font-headers font-bold text-grey-dark-2"},[_c('span',{staticClass:"text-grey-medium-2 font-normal"},[_vm._v("New Product /")]),_vm._v(" Product Details ")])}]

export { render, staticRenderFns }