<template>
  <div class="mr-2">
    <div class="flex">
      <h1 class="text-xl font-headers font-bold text-grey-dark-2">
        <span class="text-grey-medium-2 font-normal">New Product /</span>
        Product Details
      </h1>
      <div class="flex flex-grow gap-2 justify-end">
        <Button
          text="Cancel"
          type="secondary"
          size="medium"
          @click.native="cancel"
        />
        <Button
          text="Save"
          type="primary"
          :iconRight="arrowRight"
          size="medium"
          :disabled="$v.$invalid || isDisabled || isAssessmentDurationValid"
          @click.native="save"
        />
      </div>
    </div>
    <div class="bg-white shadow rounded px-2 py-3 mt-3 mb-5.5">
      <div class="max-w-1/2 min-w-600">
        <p class="font-headers font-bold text-mdl text-grey-light mb-2">
          Details and configuration
        </p>
        <p class="text-mdh text-grey-dark-1">
          Select an intuitive and distinctive name for this component and
          concise description so that customers can easily understand what the
          assessment aims to accomplish for them.
        </p>
        <div class="ml-1 my-3">
          <p class="text-sm-2 font-medium text-grey-light mb-1">Product Type</p>
          <div class="relative">
            <div
              v-if="isEditMode"
              class="absolute inset-0 bg-white z-10 opacity-50"
            />
            <Radio
              v-for="option in productType"
              :key="option.name"
              :title="option.name"
              :description="option.description"
              :disabled="isEditMode"
              :isActive="selectedProductType === option.type"
              @click.native="onSelect(option.type)"
            />
          </div>
        </div>
        <InputField
          label="Product Name"
          placeholder="e.g. Soft Skills Self Assessment"
          :onChange="(value) => (productName = value)"
          :defaultValue="productName"
          :maxlength="50"
          :isTextOk="
            !productDetails.productName.$dirty
              ? null
              : productDetails.productName.required
                ? null
                : false
            "
          errorText="Product name is required"
          class="ml-1 mb-3"
        />
        <TextArea
          label="Description"
          placeholder="Brief explanation of what the component does"
          :onChange="(value) => (productDescription = value)"
          :defaultValue="productDescription"
          :isTextOk="
            !productDetails.description.$dirty
              ? null
              : productDetails.description.required
                ? null
                : false
            "
          errorText="Product description is required"
          class="ml-1 mb-4"
        />

        <div class="ml-1">
          <CustomCheckbox
            class="w-3/4"
            title="Allow randomization of questions"
            description="Allow company admins to randomize the questions for the entire assessment"
            :onClick="changeRandomization"
            :active="randomization"
          />
          <div class="flex gap-2">
            <CustomCheckbox
              class="w-3/4"
              title="Allow completion in multiple sessions"
              description="Allow company admins to enable participants to complete the assessment in multiple sessions"
              :onClick="changeMultipleSessions"
              :active="multipleSessions"
            />
            <InputField
              v-if="!multipleSessions"
              label="Time allocation (min)"
              :onChange="(value) => (timeAllocation = value)"
              :defaultValue="timeAllocation"
              :disabled="multipleSessions"
              type="number"
              :isTextOk="multipleSessions ? null : +timeAllocation > 0 ? null : false"
              class="w-125"
              errorText="Time allocation must be at least 30 min"
            />
          </div>
        </div>
        <CustomCheckbox
            class="w-3/4"
            title="Allow going back and changing responses"
          description="Allow company admins to enable participants to change responses for previously answered questions."
          :onClick="changeGoBack"
            :active="goBack"
          />
        <NewSelect
            label="When to Publish Results for Employees"
            :options="publishResultsOptions"
            :value="publishResults"
          :onSelect="setPublishResults"
            :preselectFirst="false"
            data-testid="results-dropdown"
          />
          <InputField
            label="Assessment duration"
            :onChange="(value) => (assessmentDuration = value)"
            :defaultValue="assessmentDuration"
            :isTextOk="isAssessmentDurationOk"
            class="w-125 mt-3"
            errorText="Invalid input format. Please enter a single number or a number range (number - number)" />
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import { required } from "vuelidate/lib/validators";
import moment from "moment";
import arrowRight from "@/assets/img/icons/arrow-right-white.svg";
import InputField from "../../../components/InputField/InputField.vue";
import NewSelect from "../../../components/Select/NewSelect.vue";
import TextArea from "../../../components/TextArea";
import Radio from "../../../components/Radio/Radio.vue";
import CustomCheckbox from "../../../components/CustomCheckbox/CustomCheckbox.vue";

export default {
  name: "ProductDetails",
  components: { Radio, InputField, TextArea, CustomCheckbox, NewSelect },
  validations: {
    productName: { required },
    productDescription: { required },
  },
  data: () => ({
    arrowRight,
    productType: [
      {
        name: "Organizational",
        description:
          "Participants will assess the performance of the entire organization, as a whole entity.",
        type: 1,
      },
      {
        name: "Individual",
        description:
          "Participants will assess their your own performance, using their personal perspective.",
        type: 0,
      },
    ],
    selectedProductType: 0,
    randomization: true,
    multipleSessions: true,
    goBack: false,
    firstLoadForDuration: true,
    firstLoadForTime: true,
    publishResults: { label: "Upon assessment completion", value: 2 },
    publishResultsOptions: [
      { label: "Upon assessment completion", value: 2 },
      { label: "After admin publish", value: 4 },
      { label: "Upon assessment close date", value: 3 },
      { label: "Never", value: 1 },
    ],
  }),
  computed: {
    ...mapState({
      productDetails: (state) => state.products.productDetails,
    }),
    productId() {
      return this.$route.params.productId;
    },
    isDisabled() {
      return +this.timeAllocation > 0 ? false : true;
    },
    productName: {
      get() {
        return this.productDetails.productName;
      },
      set(value) {
        this.setProductNameForProduct({ name: value });
      },
    },
    productDescription: {
      get() {
        return this.productDetails.description;
      },
      set(value) {
        this.setProductDescription({ description: value });
      },
    },
    timeAllocation: {
      get() {
        const maxTimeAllocation = this.productDetails.maxTimeAllocation;
        const time = maxTimeAllocation?.includes(":")
          ? moment
            .duration(maxTimeAllocation)
            .asMinutes()
            .toString()
          : maxTimeAllocation;
        const defaultTime = (!time || time === '0') && this.firstLoadForTime ? "30" : time;
        this.firstLoadForTime = false;
        return defaultTime;
      },
      set(value) {
        this.setTimeAllocation({ time: value });
      }
    },
    assessmentDuration: {
      get() {
        const assessmentDuration = this.productDetails.duration;
        const defaultDuration = !assessmentDuration && this.firstLoadForDuration ? "30" : assessmentDuration;
        this.firstLoadForDuration = false;
        return defaultDuration;
      },
      set(value) {
        this.setAssessmentDuration({ time: value });
      },
    },
    isAssessmentDurationOk() {
      const regex = '^(?:[1-9]\\d*|\\d+\\s?-\\s?\\d+)$'
      if (this.assessmentDuration.toString().trim().match(regex)) {
        return null;
      }
      return false;
    },
    isEditMode() {
      const id = this.productId;
      if (id && id !== "new-product") return true;
      return false;
    },
    isAssessmentDurationValid() {
      if (this.assessmentDuration && this.isAssessmentDurationOk == null) return false;
      return true;
    },
  },
  methods: {
    ...mapActions({
      saveProductDetails: "products/saveProductDetails",
      editProductDetails: "products/editProductDetails",
      setProductName: "products/setProductName",
      setProductNameForProduct: "products/setProductNameForProduct",
      setProductDescription: "products/setProductDescription",
      setTimeAllocation: "products/setTimeAllocation",
      setAssessmentDuration: "products/setAssessmentDuration",
      showError: "alerts/showError",
    }),
    onSelect(type) {
      this.selectedProductType = type;
    },
    async save() {
      const editPayload = {
        name: this.productName,
        description: this.productDescription,
        hasRandomisedQuestions: this.randomization,
        allowInMultipleSessions: this.multipleSessions,
        maxTimeAllocation: !this.timeAllocation ? "30" : this.timeAllocation,
        allowGoingBack: this.goBack,
        duration: this.assessmentDuration.toString().trim(),
        resultsPermission: this.publishResults?.value || 2,
      };
      const handleError = (error) => {
        try {
          const parsedError = JSON.parse(error.message);
          const errorPopup = {
            messageHeader: "Error",
            message: parsedError[0],
          }
          if (parsedError[0] == 'Name already exists.') {
            errorPopup.messageHeader = "Name already exist";
            errorPopup.message = 'Name already taken by another product'
          }
          this.showError(errorPopup)
        } catch {
          this.showError({
            messageHeader: "Error",
            message: "An error occured while creating product",
          })
        }
      }
      if (this.isEditMode) {
        return await this.editProductDetails({
          id: this.productId,
          payload: editPayload,
        }).then((productId) =>
          this.$router.push(`/products/${productId}/dashboard`)
        ).catch(handleError);
      } else {
        return await this.saveProductDetails({
          ...editPayload,
          surveyType: this.selectedProductType,
        }).then((productId) =>
          this.$router.push(`/products/${productId}/dashboard`)
        ).catch(handleError);
      }
    },
    cancel() {
      this.$router.push(`/products/${this.productId}/dashboard`);
    },
    changeRandomization() {
      return (this.randomization = !this.randomization);
    },
    changeMultipleSessions() {
      return (this.multipleSessions = !this.multipleSessions);
    },
    changeGoBack() {
      return (this.goBack = !this.goBack);
    },
    setPublishResults(val) {
      this.publishResults = val;
    },
  },
  mounted() {
    if (this.isEditMode) {
      this.selectedProductType = this.productDetails.surveyType;
      this.randomization = this.productDetails.hasRandomisedQuestions;
      this.multipleSessions = this.productDetails.allowInMultipleSessions;
      this.goBack = this.productDetails.allowGoingBack;
      this.publishResults = this.publishResultsOptions.find(x => x.value == this.productDetails.resultsPermission);
    }
  },
};
</script>
<style lang="scss">
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
